.settings {
    .ant-tabs {
        background: white;
        border: 1px solid #DEDEDE;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
        padding: 2rem 1rem;

        .ant-tabs-tab {
            font-size: 1.14rem;
            color: #1D1D1D !important;
            font-weight: 300;
            text-align: left;
            padding-right: 5rem;
            padding-bottom: 1.25rem;
        }
        .ant-tabs-tab-active {
            color: #100E1A !important;
            font-weight: 500;
        }

        .ant-tabs-left-bar .ant-tabs-ink-bar {
            width: 5px;
            background: #100E1A;
        }
    }
}