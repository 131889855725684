.pixel-settings {
    padding: 1.5rem 3rem;

    .heading {
        font-size: 1.43rem;
        font-weight: 400;
        color: #464646;
    }

    .ant-divider {
        background: #979797;
        height: 2px;
    }
    
    .disclaimer {
        font-size: 1.14rem;
        color: #979797;
    }
    .disclaimer.disclaimer-bottom {
        margin-top: -1rem;
        font-style: italic;
        font-size: 1rem;
    }
}