.campaigns-container {
    .main-heading-bar {
        span.sort {
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.65);
            font-weight: 400;
        }
    }
    .campaigns {
        .column {
            margin-bottom: 1rem;
        }
    }
}