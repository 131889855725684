.review-pools {
    .heading {
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 0;
        font-size: 1.28rem;
        font-weight: 300;
    }

    .ant-card {
        border-radius: 2px;

        .ant-card-body {
            padding: 0;
            padding-bottom: 0.5rem;

            .actions-div {
                .ant-btn {
                    height: 2.5rem;
                    margin-right: 0.714rem;
                    padding: 0 0.714rem;
                    display: inline-block;
                    vertical-align: middle;
                    svg {
                        width: 1.14rem;
                        height: 1.14rem;
                    }
                    .icon {
                        width: 1.14rem;
                        height: 1.14rem;
                        overflow: hidden;
                        transition: width 0.25s;
                    }
                }
                .edit-btn {
                    i {
                        margin-top: 10px;
                    }
                    border: 1px solid #23202C;
                    text-decoration: none !important;
                    line-height: 2.55rem;
                }
                .edit-btn:hover {
                    background: #23202C;
                    svg * {
                        stroke: #fff;
                        fill: #fff;
                    }
                }
                .delete-btn {
                    i {
                        margin-top: 4px;
                    }
                    border: 1px solid #F84B3D;
                    background: white;
                }
                .delete-btn:hover {
                    background: #F84B3D;
                    svg * {
                        fill: #fff;
                    }
                }
            }
        }
    }
}