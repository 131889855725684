.sidebar {
    background: #F1F0F5;
    padding-top: 0.5rem;

    .ant-menu {
        height: '100%';
        border-right: 0;
        background: transparent;
        a {
            color: rgba(0, 0, 0, 0.65);
            font-weight: 500;
            vertical-align: middle;
            .anticon {
                vertical-align: middle
            }
            .text {
                vertical-align: middle;
            }
            svg {
                font-size: 1.25rem;
            }
            svg * {
                stroke: transparent;
                fill: #919eab;
            }
        }

        a:hover {
            color: rgba(0, 0, 0, 0.65);
            font-weight: 500;
            svg * {
                // stroke: #0e0f36;
                fill: #637381;
            }
        }

        a.active {
            color: #0e0f36;
            font-weight: 600;
            svg * {
                // stroke: #0e0f36;
                fill: #0e0f36;
            }
        }

        .ant-menu-item,
        .ant-menu-submenu {
            font-size: 1rem;
            margin: 0
        }
        .ant-menu-item:last-of-type,
        .ant-menu-submenu:last-of-type {
            margin-bottom: 0 !important;
        }
        .ant-menu-submenu-title {
            font-size: 1rem;
            .ant-menu-submenu-arrow {
                display: none;
            }
        }
        .ant-menu-sub {
            .ant-menu-item {
                font-size: 0.9rem;
                line-height: 0.9rem;
                height: 1.5rem;
                margin-bottom: 5px;
                a {
                    line-height: 0.8rem;
                }
            }
        }
        .ant-menu-submenu-title:hover {
            color: #141414;
            .ant-menu-submenu-arrow:after, .ant-menu-submenu-arrow:before {
                background-image: linear-gradient(to right, #141414, #141414);
            }
        }

        .ant-menu-selected,
        .ant-menu-active,
        .ant-menu-item-selected,
        .ant-menu-item-active,
        .ant-menu-submenu-open {
            background-color: transparent !important;
            .ant-menu-submenu-title {
                color: #0e0f36;
                .anticon {
                    vertical-align: middle
                }
                .text {
                    vertical-align: middle;
                }
                svg {
                    font-size: 1.25rem;
                }
                svg * {
                    fill: #0e0f36;
                }
            }
        }

        .ant-menu-selected::after,
        .ant-menu-active::after,
        .ant-menu-item-selected::after,
        .ant-menu-item-active::after {
            opacity: 0;
        }

        .ant-menu-sub {
            .ant-menu-item {
                margin-left: 0.8rem;
            }
        }
    }
}
@media (max-width: 1280px) {
    .sidebar {
        flex: 0 0 190px !important;
        max-width: 190px !important;
        min-width: 190px !important;
        width: 190px !important;
    }
}