.login-form {
    height: 100%;
    .form-button {
        display: block;
        background: #00DB6E;
        color: #FFFFFF;
        font-size: 1rem;
        text-align: center;
        width: 100%;
        border: none;
        outline: none;
    }
}