.snapshot {
    background: #F1F0F5;

    .heading {
        font-size: 1.14rem;
        color: #5F5F5F;
    }

    .no-data {
        margin-top: 4rem;
        padding: 0;
        background: url('../../../assets/images/backgrounds/backgroundPLaceholder.svg');
        background-position-y: 4.3rem;
        background-position: bottom right;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fff;
        min-height: 18.357rem;
        border-radius: 4px;
        margin-bottom: 2.88rem;

        .ant-card-body {
            padding: 3.12rem 4rem;

            .heading {
                font-size: 1.786rem;
                font-weight: 500;
                color: #242425;
                margin-bottom: 0.236rem;
            }

            .text {
                color: rgba(0, 0, 0, 0.45);
                font-size: 1.143rem;
                width: 60%;
                margin-bottom: 1.26rem;
            }

            .ant-btn {
                background: #FF2F2F;
                color: #fff !important;
                text-decoration: none !important;
                font-size: 1rem;
                font-weight: 500;
                outline: none !important;
                border-color: #FF2F2F !important;
                height: 2.786rem;
                border-radius: 2px;
            }
            .ant-btn:hover,
            .ant-btn:active,
            .ant-btn:focus,
            .ant-btn:focus-within {
                background: rgba(255, 47, 47, 0.85) !important;
            }
        }
    }
}