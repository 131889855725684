.user-profile-form {
    .ant-form-item {
        margin-bottom: 1rem;

        .label {
            font-size: 1rem;
            color: #595959;
        }

        .ant-input, .ant-select-selection {
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            height: 2.5rem;
            font-size: 1rem;
        }

        .ant-select-selection__rendered {
            height: 2.5rem;
            display: flex;
            align-items: center;
        }

        .ant-btn-default {
            border-radius: 3;
            background: #36C626;
            color: #fff;
            height: 2.5rem;
            font-size: 1rem;
            font-weight: 600;
            padding: 0 1.71rem;
            margin: 0;
            border: none !important;
            outline: none !important;

            svg {
                width: 1rem;
                height: 1rem;
        
                * {
                    fill: #fff;
                    stroke-width: 0;
                }
            }
        }
    }
}