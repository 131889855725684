.success-modal {
    text-align: center;

    .ant-modal-body {
        padding: 4rem 4rem 3rem 4rem;

        .close-modal {
            position: absolute;
            top: 2rem;
            right: 2.14rem;
            width: 1.71rem;
            height: 1.71rem;
            cursor: pointer;

            * {
                fill: #000;
                stroke-width: 0;
            }
        }

        img {
            margin-bottom: 0.47rem;
        }
    
        .title {
            font-size: 1.43rem;
            font-weight: 500;
            color: #020429;
            margin-bottom: 0.93rem;
        }
    
        .description {
            font-size: 1.143rem;
            font-weight: 400;
            color: #979797;
            margin-bottom: 1rem;
        }

        .ant-btn-default {
            border-radius: 3;
            background: #36C626;
            color: #fff;
            height: 2.5rem;
            font-size: 1rem;
            font-weight: 600;
            padding: 0 1.71rem;
            margin: 0;
            border: none !important;
            outline: none !important;

            svg {
                width: 1rem;
                height: 1rem;
        
                * {
                    fill: #fff;
                    stroke-width: 0;
                }
            }
        }
    }
}