.library {
    .tenor-logo {
        width: 200px;
        margin: auto;
        svg {
            width: 100%;
        }
        svg * {
            stroke: black;
            fill: black;
        }
    }
    .heading {
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 1.5rem;
        font-size: 1.28rem;
        font-weight: 300;
    }

    .ant-card-cover {
        height: 10rem;
        overflow: hidden;
    }

    .selected-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(24,144,255,0.3)
    }

    .media-meta {
        p {
            margin: 0;
            color: rgba(0,0,0,.45);
            font-size: 0.75rem;
        }
        .ant-checkbox-inner {
            width: 1.5rem;
            height: 1.5rem;
        }
        .ant-checkbox-inner:after {
            width: 0.48rem;
            height: 1.07rem;
            left: 4.571429px;
            top: 9.142857px;
        }
        .ant-checkbox-wrapper {
            position: absolute;
            bottom: -1px;
            right: 0;
        }
    }
}