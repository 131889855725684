.add-announcement-modal {
  width: 38rem !important;
  .ant-modal-content {
    padding: 3.14rem 4.14rem;
    .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black;
      .anticon {
        font-size: 20px;
      }
    }
    .ant-modal-header {
      padding: 0;
      border: 0;
      .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500;
      }
    }
    .ant-modal-body {
      padding: 0;
      .add-announcements-form {
        margin-top: 1.1rem;
        .ant-form-explain {
          color: red;
        }
        .submit-btn {
          border-color: #36c626;
          background: #36c626;
          color: white;
          display: block;
          width: 20rem;
          max-width: 100%;
          margin: 0 auto;
        }
        .ant-form-item-control {
          margin-bottom: 1.14rem;
          label {
            line-height: 1.57rem;
            font-size: 1.14rem;
            font-weight: 400;
          }
          .ant-input,
          .ant-select {
            margin-top: 0.71rem !important;
          }
          .upload-actions {
            margin-top: 0.71rem;
            display: flex;
            .upload-action {
              width: 7.14rem;
              height: 7.14rem;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 3px;
              color: rgba(0, 0, 0, 0.25);
              font-size: 1rem;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 0.5rem;
              cursor: pointer;
              .anticon {
                font-size: 2.25rem;
              }
            }
          }
        }
      }
    }
  }
}
