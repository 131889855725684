.manual-records {
  .edit-btn {
    margin-right: 8px;
    .anticon {
      margin-left: 0;
    }
  }
  .edit-btn:hover {
    background: black;
    border-color: black;
    svg * {
      fill: white;
    }
  }
  .delete-btn:hover {
    svg * {
      fill: white;
    }
  }
}
.add-manual-record-modal {
  width: 38rem !important;
  .ant-modal-content {
    padding: 3.14rem 4.14rem;
    .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black;
      .anticon {
        font-size: 20px;
      }
    }
    .ant-modal-header {
      padding: 0;
      border: 0;
      .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500;
      }
    }
    .ant-modal-body {
      padding: 0;
      .add-record-form {
        margin-top: 1.1rem;
        .submit-btn {
          border-color: #36C626;
          background: #36C626;
          color: white;
          display: block;
          width: 20rem;
          max-width: 100%;
          margin: 0 auto;
        }
        .ant-form-item-control {
          margin-bottom: 1.14rem;
          label {
            line-height: 1.57rem;
            font-size: 1.14rem;
            font-weight: 400;
          }
          .ant-input, .ant-select {
            margin-top: 0.71rem !important;
          }
        }
      }
    }
  }
}