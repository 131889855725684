.campaign-report {
    .ant-card {
        background: #fff;
        border-radius: 2px;
        border: none;
        box-shadow: none;
        margin-bottom: 40px;

        .ant-card-body {
            padding: 17px 0;

            .ant-tabs-bar {
                padding: 0 32px;
            }
        }
    }

    .hourwise-stats-card {
        .ant-card-body {
            padding-bottom: 0;
        }
    }

    .pie-chart-cards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .ant-card {
            .ant-card-head {
                border-bottom: 0;

                .ant-card-head-title {
                    text-align: center;
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: 500;
                    font-size: 1.21rem;
                    padding: 1.43rem 0;
                }
            }

            .ant-card-body {
                padding-top: 0;
            }

            .ant-tabs-nav-scroll {
                text-align: center;

                .ant-tabs-nav {
                    border: 1px solid #D9D9D9;
                    margin-bottom: 1.19rem;
                    border-radius: 4px;

                    .ant-tabs-tab {
                        padding: 0.48rem 1.71rem;
                        margin: 0;
                        font-size: 1.07rem;
                        color: #595959;
                    }
                    .ant-tabs-tab-active {
                        color: #fff !important;
                        background: #1890FF;
                        border-color: #1890FF;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                    .ant-tabs-tab:last-of-type.ant-tabs-tab-active {
                        border-radius: 0;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                    .ant-tabs-ink-bar {
                        display: none !important;
                    }
                }
            }
        }
    }
}