.billing-history {
    padding: 1rem 3rem 1.5rem 3rem;

    .ant-table-title {
        padding-top: 0;

        .title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .heading {
                font-size: 1.43rem;
                font-weight: 400;
                color: #464646;
            }

            .search-div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-size: 1rem;
                color: rgba(0, 0, 0, 0.85);

                .ant-input {
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    font-size: 1rem;
                    padding: 1rem;
                    margin-left: 1rem;
                }
            }
        }
    }

    .ant-table-content {
        border-top: 1px solid #979797;

        .ant-table-tbody {
            color: rgba(0, 0, 0, 0.45);

            .plan-name {
                color: rgba(0, 0, 0, 0.65);
                font-weight: 500;
            }

            .download-button {
                border: 1.2px solid #100E1A;
                border-radius: 4px;
                height: 2.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition-duration: 0.3s;

                svg {
                    width: 1.25rem;
                    height: 1.25rem;

                    * {
                        fill: #100E1A;
                    }
                }
            }
            .download-button:hover {
                background: #100E1A;
                svg * {
                    fill: white;
                }
            }
        }
    }

    .ant-divider {
        background: #979797;
        height: 2px;
    }

}