.create-campaign-tab {
  .ant-form-item-control {
    margin-bottom: 32px !important;
    p.form-labels {
      margin: 0 0 5px 0 !important;
    }
    .ant-form-explain {
      margin-left: 12px;
    }
  }
  .display-settings, .capture-settings {
    .ant-form-item-control {
      margin: 24px 0 0 0 !important;
      p.form-labels {
        font-size: 14px !important;
        // font-weight: 500 !important;
      }
    }
    .url-field {
      margin-bottom: 10px !important;
      margin-top: 10px !important;
    }
  }
  .ant-select-selection-selected-value {
    color: #727272;
    font-weight: 500;
  }
  .record-source {
    padding: 15px 17px;
    border: 1px solid #707070;
    border-radius: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    transition-duration: 0.3s;
    cursor: pointer;
    .record-source-content {
      p {
        text-align: center;
        margin: 0;
      }
      p.record-source-title {
        font-size: 13px;
        font-weight: 500;
        color: #151515;
        line-height: 22px;
        margin-bottom: 2px;
      }
      p.record-source-desc {
        color: #595959;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 21px;
      }
      .record-source-img {
        img {
          height: 72px;
        }
      }
    }
  }
  .record-source:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,0.25);
  }
  .record-source.selected {
    border: 3px solid #36C626;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  }
  .csv-upload {
    min-width: 200px;
    position: relative;
    input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
    .csv-upload-label {
      i {
        font-size: 2rem;
        margin-bottom: 15px;
        margin-top: 15px;
      }
      font-size: 1rem;
    }
  }
}