.user-modal {
    .ant-modal-content {
        border-radius: 3px;
        border: 1px solid #707070;
        box-shadow: none;
        padding: 2rem;

        .ant-modal-close {
            display: none;
        }

        .ant-modal-header {
            border: none;

            .ant-modal-title.custom {
                font-size: 1.78rem;
                color: #020429;
                display: flex;
                justify-content: space-between;
                align-items: center;

                svg {
                    width: 1.71rem;
                    height: 1.71rem;
                    cursor: pointer;

                    * {
                        color: rgba(0, 0, 0, 0.65);
                    }
                }
            }
        }

        .ant-modal-body {
            .ant-form {
                .ant-input-group {
                    .label {
                        font-size: 1.14rem;
                        color: #464646;
                        font-weight: 400;
                        margin-bottom: 0.5rem;
                    }

                    .ant-input {
                        border-radius: 3px;
                        border: 1px solid rgba(0, 0, 0, 0.15)
                    }

                    .ant-select {
                        width: 100%;
                    }

                    .ant-select-dropdown-menu-item {
                        border-bottom: 2px solid;
                    }
                }

                .submit-button {
                    width: 100%;
                    background: #36C626;
                    color: #fff !important;
                    border: none !important;
                    outline: none !important;
                    font-size: 1rem;
                    font-weight: 600;
                    border-radius: 4px;
                    height: 2.86rem;
                }
                .submit-button:hover,
                .submit-button:active,
                .submit-button:focus,
                .submit-button:focus-within {
                    background: rgba(54, 198, 38, 0.9);
                }
            }
        }
    }
}

.option-span {
    margin-bottom: 0;
}

.role-permissions-data {
    .title {
        font-size: 0.71rem;
        color: #595959;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }
    p {
        font-size: 0.71rem;
        font-weight: 400;
        display: flex;
        align-items: center;

        svg {
            width: 0.93rem;
            height: 0.93rem;
            margin-right: 0.5rem;
        }
    }
}