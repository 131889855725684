.alerts-row {
  display: flex;
  margin-bottom: 50px;
  .alerts-row-column {
    flex: 1;
    .alert-card {
      padding: 25px 30px;
      border: 1px solid #707070;
      border-radius: 3px;
      height: 100%;
      cursor: pointer;
      transition-duration: 0.3s;
      p {
        margin-bottom: 0;
      }
      .alert-card-title {
        position: relative;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 21px;
        font-weight: 500;
        color: #151515;
        a {
          font-size: 14px;
        }
        .right {
          position: absolute;
          right: 0;
        }
      }
      .alert-card-description {
        margin-bottom: 21px;
        color: #595959;
      }
    }
    .alert-card.active {
      border: 1px solid #36c626;
    }
    .alert-card.has-error {
      border: 1px solid #ff2f2f;
      box-shadow: 0 0 8px #ff2f2f !important;
      .ant-alert {
        margin: 10px 0;
        width: 100%;
      }
      .alert-card-title {
        color: #ff2f2f;
      }
    }
    .coming-soon {
      // pointer-events: none;
      filter: grayscale(1);
      overflow: hidden;
      .ant-switch {
        display: block;
      }
    }
    // .coming-soon:after{
    //   position: absolute;
    //   content: "Coming Soon";
    //   font-weight: bold;
    //   top: -15px;
    //   right: -55px;
    //   transform-origin: 0 0;
    //   transform: rotate(45deg);
    //   background: #02032a;
    //   color: white;
    //   padding: 0 20px;
    // }
    .alert-card:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    }
  }
}
