.review-sources {
	.heading {
			color: rgba(0, 0, 0, 0.65);
			margin-bottom: 0;
			font-size: 1.28rem;
			font-weight: 300;
	}

	.ant-card {
			border-radius: 2px;

			.ant-card-body {
					padding: 0;
					padding-bottom: 0.5rem;

					.actions-div {
							.ant-btn {
									height: 2.5rem;
									margin-right: 0.714rem;
									padding: 0 0.714rem;
									display: inline-block;
									vertical-align: middle;
									svg {
											width: 1.14rem;
											height: 1.14rem;
									}
									.icon {
											width: 1.14rem;
											height: 1.14rem;
											overflow: hidden;
											transition: width 0.25s;
									}
							}
							.edit-btn {
									border: 1px solid #23202C;
									text-decoration: none !important;
									line-height: 2.55rem;
							}
							.edit-btn:hover {
									background: #23202C;
									color: white;
									svg * {
											fill: #fff;
									}
							}
							.delete-btn {
									border: 1px solid #F84B3D;
									background: white;
							}
							.delete-btn:hover {
									background: #F84B3D;
									color: white;
									svg * {
											fill: #fff;
									}
							}
					}
			}
	}
}
.add-source-modal {
	width: 70rem !important;
	.ant-modal-body {
			padding: 24px 0;
	}
	.ant-modal-content {
		padding: 3.14rem 4.14rem;
		.ant-modal-close {
			right: 3.14rem;
			top: 2.34rem;
			color: black;
			.anticon {
				font-size: 20px;
			}
		}
		.ant-modal-header {
			padding: 0;
			border: 0;
			.ant-modal-title {
				font-size: 1.78rem;
				font-weight: 500;
			}
		}
		.source-box {
			border: 1px solid rgba(0,0,0,0.15);
			border-radius: 3px;
			transition-duration: 0.3s;
			height: 10rem;
			cursor: pointer;
			img {
				max-width: 70%;
				display: block;
				margin: auto;
			}
			.text {
				visibility: hidden;
				color: #36C626;
				font-weight: 500;
				text-align: center;
			}
		}
		.source-box:hover {
			box-shadow: 0 3px 6px rgba(0,0,0,0.16);
			.text {
				visibility: visible;
			}
		}
	}
}