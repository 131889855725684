.pixel-verification-action {
    .disclaimer {
        font-size: 1.14rem;
        color: #979797;
    }
    .disclaimer.disclaimer-bottom {
        margin-top: -1rem;
        font-style: italic;
        font-size: 1rem;
    }
    
    .pixel-btn {
        background: #36C626;
        border-color: #36C626;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 3px;
        height: 2.5rem;
        margin: 1rem 0 0rem 0;
        padding: 0rem 2rem 0rem 2rem;

        .anticon svg {
            width: 1.25rem;
            height: 1.25rem;
            
            * {
                fill: #fff !important;
            }
        }
        .anticon + span {
            margin-left: 0.25rem;
        }
    }
    .pixel-btn:hover,
    .pixel-btn:active,
    .pixel-btn:focus,
    .pixel-btn:focus-within {
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    }
    .pixel-btn.wordpess-button {
        padding-top: 0.25rem;
    }

    .ant-input {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 1rem;
        padding: 1rem;
        // width: 40%;
        height: 2.86rem;
        display: block;
    }

    .error-label {
        display: none;
    }
    .invalid .error-label {
        display: block;
    }

    .has-error .ant-input {
        border-color: #f5222d;
    }

    .ant-alert {
        position: absolute;
        top: 0px;
        border: 1px solid #FF1A2E;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.11);
        border-radius: 3px;
        width: 50%;
        left: 25%;
    }

    .skip {
        cursor: pointer;
        color: #8C8C8C;
        font-size: 1rem;
        font-weight: 600;
        margin-left: 1.57rem;
    }
}