.records-table {
    .ant-table {
        font-size: 1rem;

        .ant-table-tbody > tr > td {
            color: rgba(0, 0, 0, 0.45);

            .details-div {
                display: flex;
                flex-flow: row;
                // justify-content: space-between;
                align-content: center;

                img {
                    margin-right: 1rem;
                }
                .text {
                    display: flex;
                    flex-flow: column wrap;
                    justify-content: center;
                    margin: 0;

                    .name {
                        color: rgba(0, 0, 0, 0.65);
                        font-weight: 500;
                    }
                    .email {
                        max-width: 10rem;
                        font-size: 0.8rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .delete-button {
                height: 45.75px;
                font-size: 1rem;
            }
            .delete-button:hover {
                background: #F84B3D !important;
                svg * {
                    fill: white;
                }
            }

            .record-status {
                margin-bottom: 0;

                .ant-switch {
                    margin-left: 0.5rem;
                    vertical-align: baseline;
                }
                .ant-switch-checked {
                    background: #36C626;
                }
            }
        }
    }
}