.steps-card {
    margin-top: 4.286rem;
    padding: 0;

    .ant-card-body {
        padding: 1.857rem 2.43rem;

        .left {
            border: 1px solid #979797;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 1.836rem;
            padding-bottom: 2.52rem;

            .number {
                font-size: 2.857rem;
                font-weight: 500;
                color: #242425;
                margin-bottom: 0.02rem;
            }

            .text {
                font-size: 1.143rem;
                color: #242425;
                font-weight: 400;
                margin-bottom: 1.764rem;
            }

            .ant-btn {
                font-size: 1rem;
                font-weight: 600;
                background: #36C626;
                border: none !important;
                outline: none !important;
                color: #fff;
                padding: 0.714rem 1.143rem;
                height: 2.857rem;
                border-radius: 4px;

                svg {
                    width: 1.286rem;
                    height: 1.286rem;
                    vertical-align: text-bottom;
                    margin-left: 0.43rem;

                    * {
                        fill: #fff;
                    }
                }
            }
            .ant-btn:hover {
                background: rgba(54, 198, 38, 0.85);
            }
        }

        .right {
            .heading {
                color: #242425;
                font-size: 1.786rem;
                font-weight: 500;
                margin-bottom: 0.236rem;
            }

            .disclaimer {
                font-size: 1.143rem;
                color: rgba(0, 0, 0, 0.45);
                font-weight: 400;
                margin-bottom: 0.76rem;
            }

            .step-name {
                color: #242425;
                background: rgba(54, 198, 38, 0.33);
                padding: 2px 7px;
                display: inline-block;
                border-radius: 3px;
                font-size: 1.143rem;
                font-weight: 500;
                margin-bottom: 0.59rem;
            }

            .step-detail {
                color: rgba(0, 0, 0, 0.65);
                font-size: 1.0rem;
                font-weight: 400;
                margin-bottom: 0.36rem;
            }

            .bottom-text {
                font-size: 1.143rem;
                font-style: italic;
                color: rgba(0, 0, 0, 0.65);
                font-weight: 400;
            }
        }

        .image {
            padding: 0 !important;

            svg {
                width: 20.467rem;
                max-width: 100%;
                height: 14.418rem;
            }
        }
    }
}