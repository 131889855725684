.integrations {
    padding: 1.5rem 3rem;

    .heading {
        font-size: 1.43rem;
        font-weight: 400;
        color: #151515;
    }

    .ant-divider {
        background: #979797;
        height: 2px;
    }

    .disclaimer {
        font-size: 1.14rem;
        color: #979797;
    }
    .disclaimer.disclaimer-bottom {
        margin-top: -1rem;
        font-style: italic;
        font-size: 1rem;
    }

    .ant-input {
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 1rem;
        padding: 1rem;
        width: 40%;
        display: block;
    }

    .integration-boxes {
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-flow: row wrap;
        margin-top: 2.5rem;
        margin-bottom: -2.5rem;

        .integration-box {
            border: 1px solid #707070;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 2rem;
            min-height: 10rem;
            margin-bottom: 2.5rem;
            cursor: pointer;

            img {
                max-width: 8rem;
            }

            a, span {
                font-size: 1rem;
                font-weight: 500;
                color: #36C626;
                display: flex;
                justify-content: space-between;
                align-items: center;

                svg {
                    width: 0.75rem;
                    height: 0.75rem;
                    margin-left: 0.25rem;

                    * {
                        fill: #36C626;
                    }
                }
            }
            a:hover,
            a:active,
            a:focus,
            a:focus-within {
                color: rgba(54, 198, 38, 0.9) !important;

                svg * {
                    fill: rgba(54, 198, 38, 0.9) !important;
                }
            }

            span {
                // font-size: 0.95rem;
            }
        }
        .integration-box.active {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }
    }
}