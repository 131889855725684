.send-code-to-teammate {
    .pixel-btn {
        background: #36C626;
        border-color: #36C626;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 3px;
        height: 2.5rem;
        margin: 1rem 0 1rem 0;
        padding: 0rem 2rem 0rem 2rem;

        .anticon svg {
            width: 1.25rem;
            height: 1.25rem;

            * {
                fill: #fff !important;
            }
        }
        .anticon + span {
            margin-left: 0.25rem;
        }
    }
    .pixel-btn:hover,
    .pixel-btn:active,
    .pixel-btn:focus,
    .pixel-btn:focus-within {
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    }

    .ant-input {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 1rem;
        padding: 1rem;
        height: 2.5rem;
    }
    .has-error .ant-input {
        border-color: #f5222d;
    }

    .error-label {
        display: none;
    }
    .has-error .error-label {
        display: block;
    }
}