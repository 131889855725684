.library-modal {
  width: 38rem !important;
  .ant-modal-content {
    padding: 3.14rem 4.14rem;
    .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black;
      .anticon {
        font-size: 20px;
      }
    }
    .ant-modal-header {
      padding: 0;
      border: 0;
      margin-bottom: 2.14rem;
      .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500;
      }
    }
    .ant-modal-body {
      padding: 0;
    }

    .choose-image-from-library {
      max-height: 30rem;
      overflow: scroll;
    }
  }
}