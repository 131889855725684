.billing-plan {
    padding: 1.5rem 3rem;

    .heading {
        font-size: 1.43rem;
        font-weight: 400;
        color: #464646;
    }

    .ant-divider {
        background: #979797;
        height: 2px;
    }
    
    .plan-btn {
        font-size: 1rem;
        font-weight: 500;
        border-radius: 4px;
        height: 2.5rem;
        margin: 1rem 0 2rem 0;
        padding: 0rem 2rem 0rem 2rem;
    }
    .plan-btn:hover,
    .plan-btn:active,
    .plan-btn:focus,
    .plan-btn:focus-within {
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    }
    .plan-btn.success {
        background: #36C626;
        border-color: #36C626;
        color: #fff;
        margin-right: 1rem;
    }
    .plan-btn.cancel {
        background: #fff;
        border-color: #979797;
        border-width: 2px;
        color: #979797;
        padding: 0rem 1rem 0rem 1rem;
    }
}