.facebook-review {
  p {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 15px;
    color: black;
  }
  .fb-review-footer {
    margin-top: 20px;
    text-align: center;
    button {
      width: 130px;
      font-weight: 500;
      font-size: 17px;
      height: 36px;
    }
  }
}
