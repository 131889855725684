.send-to-teammate-modal {
    text-align: center;

    .ant-modal-body {
        padding: 2rem 4rem;

        .close-modal {
            position: absolute;
            top: 2rem;
            right: 2.14rem;
            width: 1.71rem;
            height: 1.71rem;
            cursor: pointer;

            * {
                fill: #000;
                stroke-width: 0;
            }
        }

        svg {
            width: 5.59rem;
            height: 5.43rem;
            max-width: 100%;
            margin-bottom: 0.76rem;
    
            * {
                fill: #FF2F2F;
                stroke: #FF2F2F;
            }
        }
    
        .title {
            font-size: 1.43rem;
            font-weight: 500;
            color: #020429;
            margin-bottom: 0.93rem;
        }
    
        .description {
            font-size: 1.143rem;
            font-weight: 400;
            color: #979797;
        }
    }
}