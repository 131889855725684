.records {

    .ant-card {
        border-radius: 2px;

        .ant-card-body {
            padding: 1.5rem 1rem 0.5rem 1rem;

            .multiple-actions-div {
                margin-bottom: 1rem;
                display: flex;
                justify-content: space-between;
                flex-flow: row wrap;

                div {
                    display: inline-block;
                }
                .buttons-div {
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    flex-flow: row wrap;

                    .ant-btn.ant-btn-danger {
                        margin-right: 0.5rem;
                        background: #23202C;
                        border-color: #23202C !important;
                        color: #FFFFFF;
                    }
                    .ant-btn.ant-btn-danger:active,
                    .ant-btn.ant-btn-danger:hover {
                        background: rgba(35, 32, 44, 0.9);
                        color: #FFFFFF;
                    }

                    .ant-btn.ant-btn-primary {
                        background: #36C626;
                        border-color: #36C626 !important;
                        color: #FFFFFF;
                    }
                    .ant-btn.ant-btn-primary:active,
                    .ant-btn.ant-btn-primary:hover {
                        background: rgba(54, 198, 38, 0.9);
                        color: #FFFFFF;
                    }
                }
                .buttons-div.hidden {
                    visibility: hidden;
                }
                .search-div {
                    flex: 0.5;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row wrap;

                    p.search-label {
                        flex: 0.3;
                        margin-bottom: 0;
                        align-self: center;
                    }
                    .ant-input-search {
                        flex: 1;
                        width: auto;
                    }
                }
            }
        }
    }
}