.pixel-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // width: 50%;

    .action {
        border: 1px solid #DEDEDE;
        background: #fff;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2.21rem 1.43rem;
        margin-bottom: 7rem;

        svg {
            width: 4.286rem;
            height: 4.286rem;
        }

        .heading {
            font-size: 1.286rem;
            font-weight: 500;
            color: #585858;
            margin-bottom: 0;
        }

        .sub-heading {
            color: #8C8C8C;
            font-weight: 400;
            font-size: 0.86rem;
        }

        .tag-white {
            display: none;
        }
    }
    .left-action {
        margin-right: 3.21rem;
    }
    .action:hover {
        cursor: pointer;
        background: #FF2F2F;

        .heading, .sub-heading {
            color: #fff;
        }
    }
    .left-action:hover {
        svg {
            display: none;
        }

        .tag-white {
            display: inline-block;
        }
    }
    .right-action:hover {
        svg {
            * {
                fill: #fff !important;
                stroke: #fff;
            }
        }
    }
}