.user-announcement {
  padding-top: 0;
}
.announcement-preview {
  margin: 0;
  .announcement-previewimage {
    width: 48px;
    height: 48px;
    display: inline-block;
    // border: 1px solid #979797;
    background: #fafafa;
    border-radius: 4px;
    vertical-align: middle;
  }
  .announcement-content {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    width: calc(100% - 58px);
    .announcement_title {
      margin: 0;
      width: 100%;
    }
    .announcement_message {
      width: 100%;
      color: rgba(0, 0, 0, 0.45);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      margin-bottom: 0;
    }
    .announcement_message:before,
    .announcement_message:after {
      content: '"';
    }
  }
}
