.circle-ripple {
  background-color: #35ffc3;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  animation: live_preview_pulse 0.7s linear infinite;
}

@keyframes live_preview_pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(#65ff78, 0.3),
                0 0 0 0.25em rgba(#65ff78, 0.3),
                0 0 0 0.75em rgba(#65ff78, 0.3),
                0 0 0 1.25em rgba(#65ff78, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.25em rgba(#65ff78, 0.3),
                0 0 0 0.75em rgba(#65ff78, 0.3),
                0 0 0 1.25em rgba(#65ff78, 0.3),
                0 0 0 2em rgba(#65ff78, 0);
  }
}
.preview-sidebar {
  width: 35%;
  height: 100%;
  position: fixed;
  top: 64px;
  left: 0;
  background: rgba(2,4,41,0.95);
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.45s;
  .live-preview {
    position: absolute;
    bottom: 0;
    margin-bottom: 85px;
    width: 100%;
  }
}
.preview-sidebar.visible {
  transform: translateX(0);
}
.callout-form-container {
  width: 65%;
  height: calc(100% - 64px);
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 1000;
  background: white;
  transform: translateX(100%);
  transition: transform 0.45s 0.3s;
  .ant-card {
    opacity: 0;
    transition: opacity 0.3s;
  }
  .template-select {
    width: 300px !important;
    .ant-select-selection--single {
      display: flex;
      height: auto;
    }
  }
  .ant-card {
    height: 100%;
    .ant-card-body {
      height: 100%;
      .ant-tabs {
        height: 100%;
        .ant-tabs-tab {
          border-right: 1px solid #BFBFBF;
        }
        .ant-tabs-tab-active {
          border-right: 6px solid #020429;
        }
        .ant-tabs-content {
          height: 100%;
          padding: 0;
          .tab-body > div{
            position: relative;
            height: 100%;
            overflow: scroll;
            scroll-behavior: smooth;
            padding: 0 60px;
          }
          .tab-header {
            position: relative;
            padding-bottom: 15px;
            padding-left: 13px;
            border-bottom: 1px solid #979797;
            position: sticky;
            padding-top: 30px;
            top: 0;
            background: white;
            z-index: 999;
            h3 {
              font-size: 20px;
              line-height: 22px;
              font-weight: 400;
              display: inline-block;
              margin: 0;
              padding-top: 5px;
            }
            .right {
              position: sticky;
              left: 100%;
            }
          }
          .tab-body {
            height: 100%;
            padding-top: 0;

            .ant-form-item-control {
              margin: 16px 0;
              p.form-labels {
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 22px;
                color: #464646;
                .switch-green {
                  margin-left: 16px;
                  margin-top: -3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.callout-form-container.visible {
  transform: translateX(0);
  transition: transform 0.45s;
  .ant-card {
    transition: opacity 0.3s 0.35s;
    opacity: 1;
  }
}
