.new-campaign {
  input {
    height: 36px;
    font-size: 14px;
    line-height: 22px;
  }
  .purple {
    display: inline-block;
    padding: 4px 12px;
    background-color: #E9E5FF;
  }
  .btn-red {
    background: #FF2F2F;
    border-color: #FF2F2F;
    max-height: 36px;
    width: 115px;
    color: white;
    font-size: 14px;
  }
  .btn-green {
    color: white;
    background: #36C626;
    border-color: #36C626;
    border-radius: 4px;
    width: 169px;
  }
  .switch-green {
    background: #D7DBD7;
  }
  .switch-green.ant-switch-checked {
    background: #36C626;
  }
  .switch-green.ant-click-animating-node {
    background: 0 solid #36C626;
  }
  .ant-form-item-control {
    margin: 16px 0;
    p.form-labels {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      color: #464646;
      font-weight: 500;
      .switch-green {
        margin-left: 16px;
        margin-top: -3px;
      }
    }
  }
  .ant-form-item-control.disabled {
    p.form-labels {
      color: #C7C7C7;
    }
  }
  .ant-card-body {
    padding: 6px;
  }
  .ant-tabs-bar {
    width: 22.8%;
    border-right: 1px solid #BFBFBF;
    .ant-tabs-nav-container {
      .ant-tabs-tab {
        text-align: left;
        padding: 22px 30px;
        color: rgba(29,29,29,0.65);
        font-size: 16px;
        margin-bottom: 0;
      }
      .ant-tabs-tab-active {
        background: #E9E5FF;
        font-weight: 600;
        color: #100E1A;
      }
      .ant-tabs-ink-bar {
        width: 6px;
      }
    }
  }
  .ant-tabs-left-content {
    border-left: 1px solid #BFBFBF;
  }
  .ant-tabs-content {
    overflow-x: scroll;
    padding: 30px 60px;
    .ant-tabs-tabpane {
      min-width: 678px;
    }
    .campaign-tab {
      .tab-header {
        position: relative;
        padding-bottom: 15px;
        padding-left: 13px;
        border-bottom: 1px solid #979797;
        h3 {
          font-size: 20px;
          line-height: 22px;
          font-weight: 400;
          display: inline-block;
          margin: 0;
          padding-top: 5px;
        }
        .right {
          position: sticky;
          left: 100%;
        }
      }
    }
  }
  .upload-actions {
    margin-top: 0.71rem;
    display: flex;
    .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0,0,0,0.15);
      border-radius: 3px;
      color: rgba(0,0,0,0.25);
      font-size: 1rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer;
      .anticon {
        font-size: 2.25rem;
      }
    }
  }
  .has-error {
    p.form-labels {
      color:#f5222d;
    }
  }
}