.campaign-overview {
    .ant-table-thead > tr:first-child > th:first-child {
        padding-left: 2rem;
    }
    .ant-table-tbody > tr > td {
        color: #8C8C8C;
        font-size: 1rem;

        .ant-switch {
            vertical-align: baseline;
            margin-left: 0.2rem;
        }
        .ant-switch-checked {
            background: #36C626;
        }
    }
    .ant-table-tbody > tr > td:first-child {
        padding-left: 2rem;
    }
}