.steps {
    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.14rem 0 0 0;
        position: relative;

        .logo {
            width: 15.57rem;
            margin-bottom: 1rem;
        }

        .title {
            color: #020429;
            font-weight: 500;
            font-size: 1.43rem;
            margin-bottom: 0;
        }
        
        .sub-title {
            font-weight: 400;
            color: #8C8C8C;
            font-size: 1.286rem;
            margin-bottom: 7.21rem;
            text-align: center;

            u {
                text-decoration: underline;
            }
        }
        .pixel-install-options-container {
            .sub-title {
                margin-bottom: 1.64rem;
            }

            .pixel-install-options {
                .pixel-btn.wordpess-button {
                    margin-bottom: 0;
                }

                .click-to-copy-btn {
                    margin-bottom: 1rem;
                }

                .or {
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
        .pixel-verify-action-container {
            .sub-title {
                margin-bottom: 7.5rem;
            }

            .ant-card {
                margin-bottom: 3rem;
            }
        }
        .user-profile-form-container {
            .sub-title {
                margin-bottom: 1rem;
            }
            
            .ant-card {
                width: 41.86rem;
                .ant-card-body {
                    padding-bottom: 0;
                }
            }
        }

        .ant-card {
            width: 50rem;
            max-width: 100%;
            margin-bottom: 1rem;
            border: 1px solid #DEDEDE;
            border-radius: 3px;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.11);

            .ant-card-body {
                padding: 1.64rem 2.36rem;
            }
        }

        .continue-btn {
            display: block;
            margin: auto;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            border: none !important;
            outline: none !important;
            background: #36C626;
            border-radius: 3px;
            width: 9.286rem;
            height: 2.5rem;
            margin-bottom: 2rem;

            svg {
                width: 1.2rem;
                height: 1.2rem;
                vertical-align: text-bottom;
                margin-left: 0.286rem;

                * {
                    fill: #fff;
                }
            }
        }
    }

    .right {
        background: linear-gradient(to bottom, #020429, #0F1130);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12.14rem 2rem 4.08rem 2rem;
        position: fixed;
        top: 0;
        right: 0;

        .back-to-dashboard {
            color: #FFFFFF;
            font-size: 1.43rem;
            font-weight: 500;
            margin-bottom: 3.57rem;

            svg {
                width: 1.71rem;
                height: 1.71rem;
                vertical-align: text-bottom;
                margin-left: 0.57rem;

                * {
                    fill: #fff;
                }
            }
        }

        ul {
            list-style-type: none;
            padding: 0;
            
            li {
                font-size: 1.286rem;
                font-weight: 500;
                color: #fff;
                opacity: 0.31;
                position: relative;
                margin-bottom: 2.143rem;
            }
            li::before {
                content: '';
                position: absolute;
                background: #50516B;
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                top: 0.5rem;
                left: -2.43rem;
            }
            li.done::before {
                content: '\2713';
                color: #fff;
                font-size: 0.75rem;
                padding: 0 0.15rem;
            }
            li.active {
                opacity: 1;
            }
            li.active::before {
                background: #020429;
                border: 2px solid #fff;
            }
            li::after {
                content: '';
                position: absolute;
                bottom: 1.3rem;
                height: 3.1rem;
                width: 4px;
                left: -2.05rem;
                background: #50516B;
            }
            li.active::after {
                opacity: 0.3;
            }
            li:last-of-type {
                margin-bottom: 15.5rem;
            }
            li:first-of-type::after {
                display: none;
            }
            li:hover {
                cursor: pointer;
                opacity: 1;
            }
            li.done:hover {
                cursor: auto;
                opacity: 0.3;
            }
        }

        .bottom {
            text-align: center;
            position: sticky;
            bottom: 0;
            padding-bottom: 1rem;

            .text {
                color: #BBBCCB;
                font-size: 1.143rem;
                font-weight: 400;
                // margin-bottom: 0.86rem;
            }

            svg {
                width: 7.86rem;
                max-width: 100%;
            }
        }
    }
}