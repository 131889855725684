.overall-stats {
    .column {
        // padding: 1rem;

        .ant-card {
            .antd-pro-charts-chart-card-meta {
                font-size: 0.78rem;
                svg * {
                    stroke: transparent;
                    fill: rgba(0, 0, 0, 0.45);
                }
            }

            .antd-pro-charts-chart-card-content {
                .label {
                    font-size: 1.78rem;
                    color: #000;
                    margin-bottom: 0.75rem;

                    .anticon {
                        margin-right: 0.75rem;
                        
                        svg * {
                            stroke: transparent;
                            fill: rgba(0, 0, 0, 1);
                        }
                    }
                }
            }

            .antd-pro-charts-chart-card-footer {
                font-size: 0.86rem;
                .number-label {
                    margin-right: 0.25rem;
                }
                .number {
                    font-weight: 500;
                    color: #000;
                }
            }
        }
    }
    @media(max-width: 991px) {
        .column {
            padding-bottom: 1rem;
        }
    }
}