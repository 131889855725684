.user-settings {
    padding: 1.5rem 3rem;

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        .text {
            font-size: 1.43rem;
            font-weight: 400;
            color: #151515;
        }

        .add-user-btn {
            background: #FF5858;
            border-color: #FF5858;
            color: #fff;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 3px;
            height: 2.5rem;

            .anticon + span {
                margin-left: 0.25rem;
            }
        }
        .add-user-btn:hover,
        .add-user-btn:active,
        .add-user-btn:focus,
        .add-user-btn:focus-within {
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
        }

    }

    .ant-divider {
        background: #979797;
        height: 2px;
    }

    .disclaimer {
        font-size: 1.14rem;
        color: #979797;
    }

    .ant-table {
        margin-top: 1.5rem;

        .user-name {
            text-transform: capitalize;
            margin-bottom: 0;
        }

        .role-tag {
            border-radius: 9px;
            font-size: 0.86rem;
            font-weight: 500;
            padding: 0 1rem;
        }

        .ant-table-tbody > tr > td {
            .actions-div {
                .ant-btn {
                    height: 38px;
                    width: 38px;
                    font-size: 1rem;
                    padding: 8px;
                    margin-right: 0.714rem;
                    font-weight: 500;
                }
                .ant-btn:hover {
                    svg * {
                        fill: white;
                    }
                }
                .edit-btn {
                    border: 1px solid #23202C;
                }
                .edit-btn:hover {
                    background: #23202C;
                }
                .delete-btn {
                    border: 1px solid #F84B3D;
                    background: white;
                }
                .delete-btn:hover {
                    background: #F84B3D;
                }
            }
        }
    }
}