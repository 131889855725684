.manage-accounts {
  .heading {
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 0;
      font-size: 1.28rem;
      font-weight: 300;
  }

  .ant-card {
      border-radius: 2px;
      padding: 0.57rem;
      .ant-card-body {
          padding: 0;
          padding-bottom: 0.5rem;

          .actions-div {
            .ant-btn {
                height: 2.5rem;
                margin-right: 0.714rem;
                padding: 0 0.714rem;
                background: white;
                span {
                    display: none;
                }
                svg {
                    width: 1.14rem;
                    height: 1.14rem;
                }
                .icon {
                    width: 1.14rem;
                    height: 1.14rem;
                    overflow: hidden;
                    transition: width 0.1s !important;
                }
                .text {
                    display: inline-block;
                    height: 1.14rem;
                    margin: 0;
                    width: 0;
                    overflow: hidden;
                    transition: width 0.1s !important;
                }
            }
            .edit-btn {
                border: 1px solid #23202C;
            }
            .edit-btn:hover {
                background: #23202C;
                color: white;
                svg * {
                    fill: white;
                }
            }
            .delete-btn {
                border: 1px solid #F84B3D;
                background: white;
            }
            .delete-btn:hover {
                background: #F84B3D;
                color: white;
                svg * {
                    fill: white;
                }
            }
            .pause-btn {
                border: 1px solid #FF8200;
                background: white;
                svg * {
                    stroke: #FF8200;
                    fill: #FF8200;
                }
            }
            .pause-btn:hover {
                background: #FF8200;
                color: white;
                svg * {
                    fill: white;
                }
            }
        }
      }
  }

  .ant-table {
      th:first-of-type, td:first-of-type {
          padding-left: 3rem;
      }
      th:last-of-type, td:last-of-type {
        padding-right: 3rem;
    }
  }
}
.edit-views-modal {
    .ant-modal-header {
        padding: 0;
        border-bottom: 0;
        .ant-modal-title {
            text-align: center;
            font-size: 1.42rem;
            padding-top: 3.5rem;
        }
    }
    .ant-modal-body {
        padding: 1.71rem 5.3rem;
        text-align: center;
        font-size: 1.14rem;

        .edit-view-modal-save-btn {
            margin-top: 1rem;
            background: #36C626;
            border-color: #36C626;
            color: white;
            border-radius: 3px;
        }
    }
  }